import React, {useContext, useEffect, useState} from 'react';
import s from './TableMarketShareAll.module.css'

import {Context} from "../../../index";
import {getAllStatistic} from "../../../http/brandAPI";
import TableBodyRowMarketAll from "./components/MarketShareAll/TableBodyRowMarketAll";
import TableHeadRowMarketAll from "./components/MarketShareAll/TableHeadRowMarketAll";
import {Button, Form, Spinner} from "react-bootstrap";
import {
    CURRENT_YEAR_MONTH,
    PREVIOUS_YEAR_MONTH,
    YEAR_MONTH_2019,
    YEAR_MONTH_2020, YEAR_MONTH_2021,
    YEAR_MONTH_2022, YEAR_MONTH_2023, YEAR_MONTH_2024
} from "../../../utils/consts";


const TableMarketShareAll = ({setLoadDataDone}) => {
    const {brandModel} = useContext(Context)
    const [load, setLoad] = useState(false)
    const [countMonth, setCountMonth] = useState()

    const [stateYear, setStateYear] = useState(CURRENT_YEAR_MONTH.january)
    const [loadDateLocal, setLoadDateLocal] = useState(false)

    const [data, setData] = useState()
    useEffect(() => {
        getAllStatistic(stateYear).then((data1) => {

            let arr = []
            for (let dataKey in data1.data.data) {
                data1.data.data[dataKey].forEach(el => {

                    arr.push({
                        'date': dataKey,
                        'value': el.value,
                        'brand_id': el.brand_id,
                        'brand_name': data1.data.included.brands.find((b) => b.id === el.brand_id).name,
                    })
                })
            }
            let countForMonth = {
                '1735678800': 0,
                '1738357200': 0,
                '1740776400': 0,
                '1743454800': 0,
                '1746046800': 0,
                '1748725200': 0,
                '1751317200': 0,
                '1753995600': 0,
                '1756674000': 0,
                '1759266000': 0,
                '1761944400': 0,
                '1764536400': 0,
                'allTime': 0
            }
            let year2024 = {
                '1704056400': 0,
                '1706734800': 0,
                '1709240400': 0,
                '1711918800': 0,
                '1714510800': 0,
                '1717189200': 0,
                '1719781200': 0,
                '1722459600': 0,
                '1725138000': 0,
                '1727730000': 0,
                '1730408400': 0,
                '1733000400': 0,
                'allTime': 0
            }
            let year2023 = {
                '1672520400': 0,
                '1675198800': 0,
                '1677618000': 0,
                '1680296400': 0,
                '1682888400': 0,
                '1685566800': 0,
                '1688158800': 0,
                '1690837200': 0,
                '1693515600': 0,
                '1696107600': 0,
                '1698786000': 0,
                '1701378000': 0,
                'allTime': 0
            }
            let year2022 = {
                '1640984400': 0,
                '1643662800': 0,
                '1646082000': 0,
                '1648760400': 0,
                '1651352400': 0,
                '1654030800': 0,
                '1656622800': 0,
                '1659301200': 0,
                '1661979600': 0,
                '1664571600': 0,
                '1667250000': 0,
                '1669842000': 0,
                'allTime': 0
            }
            let year2021 = {
                '1609448400': 0,
                '1612126800': 0,
                '1614546000': 0,
                '1617224400': 0,
                '1619816400': 0,
                '1622494800': 0,
                '1625086800': 0,
                '1627765200': 0,
                '1630443600': 0,
                '1633035600': 0,
                '1635714000': 0,
                '1638306000': 0,
                'allTime': 0
            }
            let year2020 = {
                '1577826000': 0,
                '1580504400': 0,
                '1583010000': 0,
                '1585688400': 0,
                '1588280400': 0,
                '1590958800': 0,
                '1593550800': 0,
                '1596229200': 0,
                '1598907600': 0,
                '1601499600': 0,
                '1604178000': 0,
                '1606770000': 0,
                'allTime': 0
            }
            let year2019 = {
                '1546290000': 0,
                '1548968400': 0,
                '1551387600': 0,
                '1554066000': 0,
                '1556658000': 0,
                '1559336400': 0,
                '1561928400': 0,
                '1564606800': 0,
                '1567285200': 0,
                '1569877200': 0,
                '1572555600': 0,
                '1575147600': 0,
                'allTime': 0
            }
            let year = new Date(stateYear * 1000).getFullYear()
            let yearEnd = Math.round(new Date(`1,1,${+year + 1}`) / 1000)
            arr.filter(({date}) => date >= stateYear && date < yearEnd).forEach(el => {
                if (stateYear == CURRENT_YEAR_MONTH.january) {
                    countForMonth[el['date']] += +el.value
                    countForMonth['allTime'] += +el.value
                }
                if (stateYear == YEAR_MONTH_2024.january) {
                    if (+el['date'] >= YEAR_MONTH_2024.january + el['date'] < CURRENT_YEAR_MONTH.january) {
                        year2024[el['date']] += +el.value
                        year2024['allTime'] += +el.value
                    }
                }
                if (stateYear == YEAR_MONTH_2023.january) {
                    if (+el['date'] >= YEAR_MONTH_2023.january + el['date'] < YEAR_MONTH_2024.january) {
                        year2023[el['date']] += +el.value
                        year2023['allTime'] += +el.value
                    }
                }
                if (stateYear == YEAR_MONTH_2022.january) {
                    if (+el['date'] >= YEAR_MONTH_2022.january + el['date'] < YEAR_MONTH_2023.january) {
                        year2022[el['date']] += +el.value
                        year2022['allTime'] += +el.value
                    }
                }
                if (stateYear == YEAR_MONTH_2021.january) {
                    if (+el['date'] >= YEAR_MONTH_2021.january + el['date'] < YEAR_MONTH_2022.january) {
                        year2021[el['date']] += +el.value
                        year2021['allTime'] += +el.value
                    }
                }
                if (stateYear == YEAR_MONTH_2020.january) {
                    if (+el['date'] >= YEAR_MONTH_2020.january + el['date'] < 1609448400) {
                        year2020[el['date']] += +el.value
                        year2020['allTime'] += +el.value
                    }
                }
                if (stateYear == YEAR_MONTH_2019.january) {
                    if (+el['date'] >= YEAR_MONTH_2019.january + el['date'] < 1577826000) {
                        year2019[el['date']] += +el.value
                        year2019['allTime'] += +el.value
                    }
                }
            })
            if (stateYear == CURRENT_YEAR_MONTH.january) setCountMonth(countForMonth)
            if (stateYear == YEAR_MONTH_2024.january) setCountMonth(year2024)
            if (stateYear == YEAR_MONTH_2023.january) setCountMonth(year2023)
            if (stateYear == YEAR_MONTH_2022.january) setCountMonth(year2022)
            if (stateYear == YEAR_MONTH_2021.january) setCountMonth(year2021)
            if (stateYear == YEAR_MONTH_2020.january) setCountMonth(year2020)
            if (stateYear == YEAR_MONTH_2019.january) setCountMonth(year2019)

            setData(arr)
            setLoadDataDone(true)
            setLoadDateLocal(true)
        })
    }, [stateYear])

    const download = () => {

        let objArray = [
            ['Бренд', 'янв.', 'фев.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.', 'ИТОГО'],
        ]
        let year = new Date(stateYear * 1000).getFullYear()
        let yearEnd = Math.round(new Date(`1,1,${+year + 1}`) / 1000)
        brandModel.IsBrand.forEach(el => {
            objArray.push([el.name, '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0',])
            let totalSum = 0
            data.filter(item => item.brand_name === el.name)
                .filter(({date}) => date >= stateYear && date < yearEnd)
                .forEach(el2 => {
                    let numberMonth = new Date(+el2.date * 1000).toLocaleDateString('en', {month: 'numeric'})
                    totalSum += +el2.value
                    objArray.forEach(el3 => {
                        if (el3.indexOf(el2.brand_name) !== -1) {
                            console.log(el3)
                            el3[numberMonth] = +el2.value / countMonth[el2.date] * 100 ? String(+el2.value / countMonth[el2.date] * 100).slice(0, 4) + '%' : '0'
                            console.log(countMonth)
                            el3[13] = String(+totalSum / +countMonth.allTime * 100).slice(0, 7) + '%'

                        }
                    })
                })
        })
        objArray.sort(function (a, b) {
            return +b[13].replace('%', '') - +a[13].replace('%', '')
        })
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = [];
            for (let index in array[i]) {
                line.push('"' + array[i][index] + '"');
            }
            str += line.join(';');
            str += '\r\n';
        }
        const universalBOM = "\uFEFF";
        let a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + str));
        a.setAttribute('download', 'marketShare .csv');
        window.document.body.appendChild(a);
        a.click();
    }


    return (
        <>
            {
                data && brandModel.IsBrand
                    ?

                    <div style={{marginTop: 15}}>
                        <div className={s.table}>
                            <div style={{
                                borderBottom: 'solid 1px grey',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignItem: 'center',
                                paddingBottom: '15px'
                            }}>
                                <div>
                                    <h3 style={{paddingLeft: 0, marginBottom: 0,}}>Процентная доля рынка всех
                                        брендов</h3>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gridGap: '20px'}}>
                                    <Button variant={"outline-light"} onClick={download}>Скачать таблицу</Button>
                                    <div>
                                        <Form>
                                            <Form.Select defaultValue={stateYear} onChange={(e) => {
                                                setStateYear(e.target.value)
                                                setLoadDateLocal(false)
                                            }
                                            }>
                                                <option value={CURRENT_YEAR_MONTH.january}>2025</option>
                                                <option value={PREVIOUS_YEAR_MONTH.january}>2024</option>
                                                <option value={YEAR_MONTH_2023.january}>2023</option>
                                                <option value={YEAR_MONTH_2022.january}>2022</option>
                                                <option value={YEAR_MONTH_2021.january}>2021</option>
                                                <option value={YEAR_MONTH_2020.january}>2020</option>
                                                <option value={YEAR_MONTH_2019.january}>2019</option>
                                            </Form.Select>
                                        </Form>
                                    </div>
                                </div>


                            </div>
                            {
                                loadDateLocal
                                    ?
                                    <>
                                        <TableHeadRowMarketAll stateYear={stateYear}/>
                                        <div style={{display: 'grid', gridTemplateColumns: '30px 1fr',}}>
                                            <div>
                                                {
                                                    data ? brandModel.IsBrand.map((currElement, index) =>
                                                            <div style={{
                                                                height: '45px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                <p style={{margin: '0'}}>{index + 1}                                                </p>
                                                            </div>
                                                        )
                                                        : false
                                                }
                                            </div>
                                            <div className={s.table_body + ' brand_body'} style={{display: 'grid'}}>
                                                {
                                                    data ? brandModel.IsBrand.map(({id, name}) =>
                                                        <TableBodyRowMarketAll countMonth={countMonth} load={load}
                                                                               setLoad={setLoad}
                                                                               key={id} brand_id={id} brand_name={name}
                                                                               data={data.filter(item => item.brand_id === id).filter(item => stateYear == CURRENT_YEAR_MONTH.january ? item : +item.date < CURRENT_YEAR_MONTH.january)}
                                                                               stateYear={stateYear}
                                                        />
                                                    ) : false
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <Spinner animation={"grow"}/>
                            }
                        </div>
                    </div>
                    :
                    <Spinner animation={"grow"}/>
            }
        </>
    );
};

export default TableMarketShareAll;